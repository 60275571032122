import React from "react";
import Conclusion from "../../components/project/Conclusion";
import CommonProjectComponent from "../../components/project/CommonProjectComponent";
import ProblemSolution from "../../components/project/ProblemSolution";
import ProjectDetailBanner from "../../components/project/ProjectDetailBanner";
import PreviousNext from "../../components/PreviousNext";
import { Seo } from "../../components/seo";
import Layout from "../../components/layout";

const AlphaTest = () => {
  const headingbanner = {
    title: `Alpha Test`,
    content: `A no-code test automation platform`,
  };

  const data = {
    images: ["alpha-test.webp", "alpha-test-2.webp"],
    firstAlt: "UI UX No-Code Test Automation Dashboard",
    firstTitle: "Automation Dashboard",
    secondAlt: "Test Run Results of Specific Projects",
    secondTitle: "Test Run Results",
  };

  const problemSolutionContent = {
    sidebar: [
      {
        title: "Industry",
        text: ["Enterprise"],
      },
      {
        title: "What we did",
        text: ["User Research", "UI UX Designing", "Usability Testing"],
      },
      {
        title: "Platform",
        text: ["Web Application"],
      },
    ],
    mainContent: [
      {
        title: "Problem",
        content: [
          `When we got the project, the platform lacked flexibility and offered limited scalability options. They lacked advanced testing capabilities which impacted the effectiveness of the platform.`,
          `Users found it difficult to customise the platform or transition from traditional programming-based approaches.`,
          `The platform had limited pre-built modules and components for creating automated tests which hampers its ability to handle complex or unique testing scenarios.`,
          `Integration was an important challenge for them as it supported a narrow range of technologies, frameworks, and platforms.`,
          `It limited the integration of certain programming languages, operating systems, or browsers which limited the scope of the platform.`,
        ],
      },
      {
        title: "Solution",
        content: [
          `We conducted in-depth user research to gain insights into the needs, pain points, and preferences of the platform's users in order to create an intuitive and user-friendly interface.`,
          `To address their challenge of limited flexibility, we offered customization options for the users to create and modify their own test modules, add custom validations, etc.`,
          `We also focused on improving the integration capabilities of the platform with popular development frameworks, continuous integration/continuous delivery tools, and other testing ecosystem components so that users can leverage their existing tools and workflows and enhance their overall productivity.`,
        ],
      },
    ],
    image: ["alpha-test-problem.webp"],
    firstAlt: "All Mockup Screens",
    firstTitle: "Project Screens",
  };

  const projectData = {
    title: "Dashboard",
    para: [
      `We created a dashboard interface for a better user experience wherein users can look into the test automation status of different projects at a glance.`,
    ],
    content: {
      imageAlt: "UI UX No-Code Test Automation Dashboard",
      imageTitle: "Automation Dashboard",
      image: ["alpha-test-projectone.webp"],
      text: `They can also filter it based on the project automation status i.e. ongoing, completed and archived. `,
    },
    content2: {
      imageAlt: "UI screen to test the action of each step",
      imageTitle: "Test Scenario",
      image: ["alpha-test-projectone-2.webp"],
      text: "We created this screen to show the step-by-step process of no-code test automation along with the preview for better user understanding and experience. Users can also customise their platform based on their requirements.",
    },
  };

  const projectData2 = {
    title: `Variable Elements`,
    para: [
      `With the use of variable elements, we allowed users to create a dynamic and adaptable test. Through this feature, we enabled users to define elements within their test scripts that may vary based on different scenarios or runtime conditions.`,
      `This enhances the flexibility and adaptability of the users to handle dynamic elements without modifying the test script.`,
    ],
    content: {
      image: ["alpha-test-projecttwo.webp"],
      imageAlt: "Validating each element of the action",
      imageTitle: "Validate Element",
    },
  };

  const projectData3 = {
    title: "Child Element",
    para: [
      `We provided this option to the users to deal with complex nested structures. It can simplify element identification and interaction, making tests more robust and reducing the effort required to handle changes in the application's structure.`,
    ],
    content: {
      image: [
        "alpha-test-projectthree.webp",
        "alpha-test-projectthree-2.webp",
        "alpha-test-projectthree-3.webp",
        "alpha-test-projectthree-4.webp",
        "alpha-test-projectthree-5.webp",
      ],
      firstAlt: "Testing Child Element",
      firstTitle: "Child Element",
      secondAlt: "Adding Child Element for the specific action",
      secondTitle: "Add Child Element",
      thirdAlt: "Selecting the properties for child element",
      thirdTitle: "Properties in Child Element",
      fourthAlt: "Setting for specific action",
      fourthTitle: "Settings",
      fifthAlt: "Select elements in the properties for specific action",
      fifthTitle: "Select Element",
    },
  };

  const conclusionData = [
    {
      para: [
        `Alpha Test is a no-code testing platform that had limited flexibility, scalability and customisation options when they reached us. However, they wanted to offer a better user experience to tap the growing market opportunities.`,
        `We added various options such as variable elements, child elements, preview etc. to enhance the platform usability, offer flexible and scalable options and improve the user experience.`,
      ],
    },
  ];

  return (
    <Layout>
      <div className="service-page">
        <ProjectDetailBanner data={data} headingbanner={headingbanner} />
        <ProblemSolution data={problemSolutionContent} />
        <CommonProjectComponent data={projectData} mode="cream" />
        <CommonProjectComponent data={projectData2} />
        <CommonProjectComponent data={projectData3} mode="dark" />
        <Conclusion conclusionData={conclusionData} />
        <PreviousNext currentProjectId={17} />
      </div>
    </Layout>
  );
};

export default AlphaTest;

export const Head = () => (
  <Seo
    title="Alpha test- Octet Design Studio"
    description="A no-code testing platform for which we conducted User Research, offered services like UI UX Designing etc. for effortlessly creating automated tests."
  />
);
